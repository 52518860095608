
import Vue from "vue";
import { server } from "@/utils/helper";
import { mapActions, mapGetters } from "vuex";
import { Book } from "@/interfaces/book.interface";

export default Vue.extend({
  name: "Book",
  data: () => ({
    id: "",
    book: {} as Book,
    downloadURL: "",
    imgURL: "",
  }),
  async created() {
    this.id = String(this.$route.params.id);
    this.book = await this.getABook({ _id: this.id }).catch(() => {
      alert("Session Expired. Log back in to continue.");
      this.$store.dispatch("logout");
      this.$router.replace("/login");
    });
    this.downloadURL = `${server.baseURL}/niklib/files/download/${this.book.filename}?token=${this.getToken}`;
    this.imgURL = `${server.baseURL}/niklib/images/${this.book.img_name}?token=${this.getToken}`;
  },
  methods: {
    ...mapActions({ getABook: "getBook", downloadABook: "getFile" }),
    navigate() {
      this.$router.go(-1);
    },
  },
  computed: {
    ...mapGetters(["getToken"]),
  },
});
