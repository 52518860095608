
import Vue from "vue";
import AddBook from "@/components/home/book/Add.vue";
import Tooltip from "@/components/shared/Tooltip.vue";
import { mapGetters } from "vuex";
import { auth } from "@/firebase";
import axios from "axios";

export default Vue.extend({
  name: "App",
  components: { Tooltip, AddBook },
  created() {
    axios.defaults.headers.common["Authorization"] =
      this.$store.getters["getToken"];
    if (this.$vuetify.breakpoint.xs)
      this.$store.commit("UPDATE_DISPLAY_STYLE", "mobile");
  },
  data: () => ({
    dialog: false,
    snackbar: false,
    snackbarColor: "",
    snackbarText: "",
    timeout: 3000,
  }),
  methods: {
    openAdd(): void {
      this.dialog = true;
    },
    closeDialog(): void {
      this.dialog = false;
    },
    openSnackbar(props: Record<string, string | boolean>): void {
      this.snackbar = Boolean(props.show);
      this.snackbarColor = String(props.color);
      this.snackbarText = String(props.text);
    },
    logout(): void {
      this.$store.dispatch("logout").then(() => this.$router.push("/login"));
    },
    home(): void {
      if (this.$route.path !== "/home") this.$router.push("/home");
    },
  },
  computed: {
    ...mapGetters({ currentUser: "getCurrentUser" }),
  },
});
