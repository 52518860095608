
import Vue from "vue";
import LoginCard from "@/components/login/LoginCard.vue";

export default Vue.extend({
  name: "Login",
  components: {
    LoginCard,
  },
});
