
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { server } from "@/utils/helper";

export default Vue.extend({
  name: "AuthorList",
  data: () => ({
    tab: 0,
    tabColor: "teal",
    snackbar: false,
    snackbarColor: "",
    snackbarText: "",
    timeout: 3000,
  }),
  async created() {
    await this.$store.dispatch("getAllBooks");
  },
  methods: {
    ...mapActions({ deleteABook: "deleteBook" }),
    getImage(img_name: string): string {
      return `${server.baseURL}/niklib/images/${img_name}?token=${this.token}`;
    },
    deleteBook(id: string, author: string): void {
      this.deleteABook({ _id: id, author: author }).then(() => {
        this.snackbar = true;
        this.snackbarColor = "teal darken-2";
        this.snackbarText = "Successfully deleted";
      });
    },
    updateTabState(index: number): void {
      this.$store.commit("UPDATE_CURRENT_TAB", index);
    },
  },
  computed: {
    ...mapGetters({
      books: "getBooks",
      token: "getToken",
      currentTab: "getCurrentTab",
    }),
  },
});
