
import Vue from "vue";
import { auth, auth2 } from "@/firebase";
import { getAuth } from "firebase/auth";

export default Vue.extend({
  name: "LoginCard",
  data: () => ({
    email: "",
    apiKey: "",
    error: "",
    overlay: false,
    dialog: false,
  }),
  async created(): Promise<void> {
    await auth.getRedirectResult().then((result) => {
      if (result.user) {
        this.overlay = true;
        this.$store
          .dispatch("loginWithGoogleRedirect", result)
          .then(async (result) => {
            if (result.success) {
              await this.$router.push("/home");
            } else {
              this.error = result.message;
            }
          })
          .catch((result) => (this.error = result.message));
      }
    });
  },
  methods: {
    async loginWithGoogleAuth(): Promise<void> {
      this.overlay = true;
      if (this.$vuetify.breakpoint.xs) {
        const provider = new auth2.GoogleAuthProvider();
        await auth.signInWithRedirect(provider);
      } else {
        this.$store
          .dispatch("loginWithGoogle")
          .then(async (result) => {
            if (result.success) {
              await this.$router.push("/home");
            } else {
              this.error = result.message;
            }
          })
          .catch((result) => (this.error = result.message));
      }
    },
    loginWithFirebaseAuth(): void {
      this.overlay = true;
      this.$store
        .dispatch("login", { email: this.email, pass: this.apiKey })
        .then(async (result) => {
          if (result) {
            await this.$router.push("/home");
          }
        })
        .catch((e) => (this.error = "An error occurred. Please try again."));
    },
    showDialogForSignUp(): void {
      this.dialog = true;
    },
  },
  watch: {
    overlay(val): void {
      val &&
        setTimeout(() => {
          this.overlay = false;
        }, 3000);
    },
  },
});
